import { BRAND_DOMAIN } from '../../constants/Branding';

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-api.bigbullexch.io/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://stage-api.bigbullexch.io`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.bigbullexch.io`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.bigbullexch.io/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://stage-api.bigbullexch.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://stage-agpay.bigbullexch.io`,
  REACT_APP_SPORT_FEED_URL: `https://stage-api.mysportsfeed.io/api/v1`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://api.${BRAND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BRAND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BRAND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.${BRAND_DOMAIN}`,
  REACT_APP_SPORT_FEED_URL: `https://api.mysportsfeed.io/api/v1`,
};

export const EnvUrlsMap = {
  development: StageUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
