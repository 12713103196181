import './ReportsSideBar.scss';
import { List, ListItem, ListItemText, Modal } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../store';
import { Button } from '@material-ui/core';

type StoreProps = {
  logout: Function;
};

const ReportsSideBar: React.FC<StoreProps> = (props) => {
  const { logout } = props;

  const logoutHandler = () => {
    logout();
  };
  return (
    <div className="competitions-menu">
      <List component="nav" disablePadding>
        <NavLink
          to="/profile"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">Profile</Button>
        </NavLink>
        <NavLink
          to="/my_bets"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">My Bets</Button>
        </NavLink>
        <NavLink
          to="/my_wallet"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">My Wallet</Button>
        </NavLink>
        <NavLink
          to="/account_statement"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">Account Statement</Button>
        </NavLink>
        <NavLink
          to="/pl_statement"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">P/L Statement</Button>
        </NavLink>
        <NavLink
          to="/commission_report:upline"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">Commision Report</Button>
        </NavLink>
        <NavLink
          to="/set-button-variables"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">Set Button Variables</Button>
        </NavLink>
        <NavLink
          to="/change-password"
          className="eventTypes-menu-nav-link"
          activeClassName="active-sidebar-link"
        >
          <Button className="">Change Password</Button>
        </NavLink>
        <ListItem
          button
          className="eventTypes-menu-nav-link logout-btn"
          onClick={logoutHandler}
        >
          <ListItemText primary="Logout" className="logout-btn" />
        </ListItem>
      </List>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(ReportsSideBar);
